import React, { useState } from 'react';
import './App.css';
import Keycloak from "keycloak-js";
import { Card } from 'primereact/card';

let initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
}

let kc = new Keycloak(initOptions);

kc.init({
  onLoad: 'login-required',
  checkLoginIframe: true,
  pkceMethod: 'S256'
}).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.info("Authenticated");
    console.log('auth', auth)
    console.log('Keycloak', kc)
    console.log('Access Token', kc.token)

    kc.onTokenExpired = () => {
      console.log('token expired')
    }
  }
}, () => {
  console.error("Authentication Failed");
});

function App() {

  const [infoMessage, setInfoMessage] = useState('');

  return (
    <div className="App">
      <header className="App-header">
        <img src="https://tf-auth0-shared-public-assets.s3.eu-central-1.amazonaws.com/yourfirm/Yourfirm_white_logo.png"/>
      </header>
      <body>
        <p>
          <h2>Hello {kc.tokenParsed?.given_name}</h2>
        </p>
        <p>
          <h1>Welcome to Yourfirm B2C job portal!</h1>
        </p>
        <p>
          <button onClick={() => { setInfoMessage(JSON.stringify(kc.tokenParsed)) }}
          >Show Access Token
          </button>
        </p>
        <Card>
          <p style={{ wordBreak: 'break-all' }} id='infoPanel'>
            {infoMessage}
          </p>
      </Card>
        <h2>Settings</h2>
        <p>
          <button type='submit' onClick={() => { kc.logout({ redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URI }) }}>Sign Out</button>
        </p>
        <p>
          <button onClick={() => { kc.accountManagement() }}>Manage Your Account</button>
        </p>
      </body>
    </div>
  );
}

export default App;
